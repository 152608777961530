<script setup>
const props = defineProps({
    moduleProgress: {
        type: Number,
        default: 0
    }
});

const circleDimension = 36;
const radius = circleDimension / 2;
const circumference = 2 * Math.PI * radius;

const dashArray = ref();

watch(() => props.moduleProgress, () => {
    if (props.moduleProgress === 0) {
        dashArray.value = `0, ${circumference}`;
    }

    dashArray.value = `${(circumference / 100) * props.moduleProgress}, ${circumference}`;
}, { immediate: true });
</script>

<template>
    <div class="base-module-progress-graph">
        <div
            class="base-module-progress-graph__text"
        >
            <ClientOnly>
                <template v-if="moduleProgress === 100 || isNaN(moduleProgress)">
                    <BaseIcon icon="check" />
                </template>
                <template v-else>
                    {{ moduleProgress }}%
                </template>
            </ClientOnly>
        </div>

        <ClientOnly>
            <svg
                class="base-module-progress-graph__circle"
                :viewbox="`0 0 ${circleDimension + 5} ${circleDimension + 5}`"
                :width="circleDimension + 5"
                :height="circleDimension + 5"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    class="base-module-progress-graph__fill"
                    :stroke-dasharray="dashArray || '0, 100'"
                    :cx="(circleDimension / 2) + 2"
                    :cy="(circleDimension / 2) + 2"
                    :r="circleDimension / 2"
                />
            </svg>
        </ClientOnly>
        <svg
            class="base-module-progress-graph__background-circle"
            :viewbox="`0 0 ${circleDimension + 5} ${circleDimension + 5}`"
            :width="circleDimension + 5"
            :height="circleDimension + 5"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                class="base-module-progress-graph__track"
                :cx="(circleDimension / 2) + 2"
                :cy="(circleDimension / 2) + 2"
                :r="circleDimension / 2"
            />
        </svg>
    </div>
</template>

<style lang=less>
.base-module-progress-graph {
    display: block;
    position: relative;
    width: 41px;
    height: 41px;
}

.base-module-progress-graph__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: .75rem;
    font-weight: 700;
    color: var(--color-alt-dark, --color-primary, #000);

    .dn-icon {
        font-size: 1.25rem;
        height: 1rem;
        width: 1.4375rem;
    }
}

.base-module-progress-graph__circle {
    transform: rotate(-90deg);
}

.base-module-progress-graph__background-circle {
    position: absolute;
    top: 1px;
    left: 0;
}

.base-module-progress-graph__track {
    stroke: rgba(0,0,0,.1);
    fill: none;
    stroke-width: 4;
}

.base-module-progress-graph__fill {
    stroke: var(--color-alt-dark, --color-primary, #000);
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    transition: stroke-dasharray 0.5s;
}
</style>